

export const ResearchLinks = [
    {name:'Submit',path:'/submit'},
    {name:'Researches',path:'/researches'},
    {name:'Researchers',path:'/researchers'},
    {name:'Institutions',path:'/institutions'},
    {name:'Themes',path:'/themes'},
]

export const ProjectsLinks = [
    {name:'Submit',path:'/projectsSubmit'},
    {name:'Projects',path:'/projectsProjects'},
]

export const ProductsLinks = [
    {name:'Submit',path:'/productSubmit'},
    {name:'Products',path:'/productProduct'},
]