

import React,{Component} from 'react';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../Utilities/assets/css/style.css';


import { APIResearcherAccountDelete, APIResearcherGetByEmail, APIShowResearcherImage, APIUpdateResearcherDetails, APIUpdateResearcherImage, APIUpdateResearcherPassword, APIUploadResearcherImage, AUTH_TOKEN } from '../../Utilities/APIS';
import {clearLocalStorageData, getIdCategory } from '../../Utilities/Functions';
import GuestUser from '../Main/GuestUser';
import NavigationMenu from '../Navigation/NavigationMenu';
import { ERROR_ALL_INPUTS_REQUIRED, ERROR_POST } from '../../Utilities/Errors';

const headers = {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${AUTH_TOKEN}`};

class Profile extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        userLoggedIn:false,
        EditProfileSuccess:'',
        UploadProfileSuccess:'',
        EditPasswordSuccess:'',
        EditProfileError:'',
        UploadProfileError:'',
        EditPasswordError:'',

        // Image 
        UploadProfileImageSuccess:'',
        UploadProfileImageError:'',
        EditProfileImageSuccess:'',
        EditProfileImageError:'',
        fileNameToDelete:'',


        ShowEditProfileSuccessAlert:false,
        ShowEditProfileImageSuccessAlert:false,
        ShowEditProfileImageErrorAlert:false,
        ShowEditProfileErrorAlert:false,

        ShowUploadProfileSuccessAlert:false,
        ShowUploadProfileImageSuccessAlert:false,
        ShowUploadProfileImageErrorAlert:false,
        ShowUploadProfileErrorAlert:false,

        ShowEditPasswordSuccessAlert:false,
        ShowEditPasswordErrorAlert:false,
        ShowEditPasswordAlert:false,
        SelectedImage:null,
        profileImage:null,
        UploadProfileImage:null,

        //  Password
        CurrentPassword:'',
        Password:'',
        PasswordRetype:'',
        PasswordInputsRequired:'',
        PasswordDoNotMatch:'',
        DBPasswordDoNotMatch:'',
        UserTypedPassword:'',

        UniversitiesData:[],
        userId:'',

        userUniversity:'',
        userEmail:'',
        userName:'',
        userTitle:'',
        userContact:'',
        userNationality:'',


        userImage:'',
        userAbout:'',
        userLevel:'',
        userTwitter:'',
        userFacebook:'',
        userInstagram:'',
        userLinkedin:'',


         // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        // model
        ShowDeleteModel:false,
        deleteId:'',
        deleteTitleName:'',



    }    
}

componentDidMount(){
    this.loadUser();
    this.initializeReduxData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});
    }
};
loadUser = ()=>{
    const user = localStorage.getItem('UserDetails');
    if (user) {
        const jsonData = JSON.parse(user)
        let userName = jsonData.name;
        let userEmail = jsonData.email;
        let userTitle = jsonData.title;
        let userContact = jsonData.contact;
        let userUniversity = jsonData.university;
        let userNationality =  jsonData.nationality;
        let userId =  jsonData.id;
        let userAbout= jsonData.about;
        let userLevel= jsonData.level;
        let userTwitter= jsonData.twitter;
        let userFacebook= jsonData.facebook;
        let userInstagram= jsonData.instagram;
        let userLinkedin= jsonData.linkedin;
        let loggedIn = jsonData.loggedIn;
        let profileImage = jsonData.profileImage;
        
        
        this.setState({fileNameToDelete:profileImage})
        this.setState({userName:userName});
        this.setState({userTitle:userTitle});
        this.setState({userLoggedIn:loggedIn});
        this.setState({userEmail:userEmail});
        this.setState({userId:userId});
        this.setState({userContact:userContact});
        this.setState({userUniversity:userUniversity});
        this.setState({userNationality:userNationality});
        this.setState({userAbout:userAbout});
        this.setState({userLevel:userLevel});
        this.setState({userTwitter:userTwitter});
        this.setState({userFacebook:userFacebook});
        this.setState({userInstagram:userInstagram});
        this.setState({userLinkedin:userLinkedin});
        this.setState({profileImage:profileImage});
    }
}

getUserPassword = async () =>{

    let email = this.state.userEmail;
    const postRequest = await axios.get(APIResearcherGetByEmail+email,{headers})
    let results = postRequest.data;
    let jsonString = JSON.stringify(results)
    let jsonResults =JSON.parse(jsonString);
    let userPassword = jsonResults.password;
    this.setState({CurrentPassword:userPassword});
}
setUserUniversity = (text) =>{this.setState({userUniversity:text.target.value})}
setUserEmail = (text) =>{this.setState({userEmail:text.target.value})}
setUserName = (text) =>{this.setState({userName:text.target.value})}
setUserContact = (text) =>{this.setState({userContact:text.target.value})}
setUserImage = (text) =>{this.setState({userImage:text.target.value})}
setUserAbout = (text) =>{this.setState({userAbout:text.target.value})}
setUserLevel = (text) =>{this.setState({userLevel:text.target.value})}
setUserNationality = (text) =>{this.setState({userNationality:text.target.value})}
setUserTwitter = (text) =>{this.setState({userTwitter:text.target.value})}
setUserFacebook = (text) =>{this.setState({userFacebook:text.target.value})}
setUserInstagram = (text) =>{this.setState({userInstagram:text.target.value})}
setUserLinkedin = (text) =>{this.setState({userLinkedin:text.target.value})}
setPassword = (text) =>{ this.setState({Password:text.target.value})}
setPasswordRetype = (text) =>{ this.setState({PasswordRetype:text.target.value})}
setTypedUserPassword = (text) =>{ this.setState({UserTypedPassword:text.target.value});this.getUserPassword()}

resetModelShowAlerts = () =>{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
}
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = () => {this.setState({ShowDeleteModel:true});}

// Function to handle the image upload and conversion to base64
handleImageUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) 
        {
            this.setState({UploadProfileImage:file})
            const reader = new FileReader();
            
            reader.onloadend = () => {
            this.setState({SelectedImage:reader.result}); // Set the base64 image data to state
            // console.log(reader.result)
            };

            reader.readAsDataURL(file); // Convert image to base64 string
        }
};

validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


updateUserDetails = async () =>{
    let userId =  this.state.userId;
    let university = this.state.userUniversity;
    let nationality = this.state.userNationality;
    let email = this.state.userEmail;
    let fullName = this.state.userName;
    let contact = this.state.userContact;
    let about = this.state.userAbout;
    let level = this.state.userLevel;
    let twitter = this.state.userTwitter;
    let facebook = this.state.userFacebook;
    let instagram = this.state.userInstagram;
    let linkedin = this.state.userLinkedin;
    let title = this.state.userTitle;

    if (fullName.length === 0 || contact.length ===0 ||  email.length === 0)
    {
        this.setState({AllInputsRequired:"Name, Contact OR Email  Inputs Are Required"}); 
    }
    
    else
    {
        if (this.validateEmail(email)) 
            {
                const formData = new FormData();
                formData.append('university', university);
                formData.append('nationality', nationality);
                formData.append('email', email);
                formData.append('fullName', fullName);
                formData.append('contact', contact);
                formData.append('about', about);
                formData.append('level', level);
                formData.append('twitter', twitter);
                formData.append('facebook', facebook);
                formData.append('instagram', instagram);
                formData.append('linkedin', linkedin);
                formData.append('updateId', userId);

                try
                    {
                        const postRequest = await axios.put(APIUpdateResearcherDetails, formData,{headers})
                        let result = postRequest.data;
                        let Details = {profileImage:this.state.profileImage,name:fullName,linkedin:linkedin,instagram:instagram,facebook:facebook,twitter:twitter,level:level,about:about, nationality:nationality,email:email,contact:contact,title:title, university:university,id:userId,loggedIn:true};
                        localStorage.setItem('UserDetails',JSON.stringify(Details));
                        this.setState({EditProfileSuccess:result});
                        this.setState({ShowEditProfileSuccessAlert:true});
                        this.loadUser();
                    }
                    catch (error)
                        {this.setState({EditProfileError:error.message}); this.setState({ShowEditProfileErrorAlert:true});};
            } 
        else {this.setState({emailError: 'Please Enter A Valid Email Address.'});}
    }
}


updateUserPassword = async () =>{
    let userId =  this.state.userId;
    let password = this.state.Password;
    let passwordRetype = this.state.PasswordRetype;

    if (password.length === 0 || passwordRetype.length ===0)
    {this.setState({PasswordInputsRequired:"Password  Inputs Are Required"});}
    else
    {
        if (password !== passwordRetype)
            {this.setState({PasswordDoNotMatch:"Passwords Do Not Match"}); }
        else 
        {
            if (this.state.UserTypedPassword !== this.state.CurrentPassword)
                {this.setState({DBPasswordDoNotMatch:"Exiting Passwords Do Not Match New Password"}); }
            else 
            {
                const formData = new FormData();
                formData.append('password', password);
                formData.append('updateId', userId);

                try
                    {
                        const postRequest = await axios.put(APIUpdateResearcherPassword,formData,{headers})
                        let result = postRequest.data;
                        this.setState({EditPasswordSuccess:result});
                        this.setState({ShowEditPasswordSuccessAlert:true});
                    }
                    catch (error)
                        {this.setState({EditPasswordError:error.message}); this.setState({ShowEditPasswordErrorAlert:true});};
            }
        }
    }
}

uploadUserImage = async () =>{
    
    let userId =  this.state.userId;
    let file = this.state.UploadProfileImage;

    if (file.length === 0){
        this.setState({AllInputsRequired:ERROR_ALL_INPUTS_REQUIRED}); 
    }
    
    else
    {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('updateId', userId);


        try
            {
                const postRequest = await axios.put(APIUploadResearcherImage, formData,{headers})
                let result = postRequest.data;
                this.setState({UploadProfileImageSuccess:result});
                this.setState({ShowUploadProfileImageSuccessAlert:true});
            }
            catch (error)
                {this.setState({UploadProfileImageError:error.message}); this.setState({ShowUploadProfileErrorAlert:true});};
    }
}

updateUserImage = async () =>{
    
    let userId =  this.state.userId;
    let file = this.state.UploadProfileImage;
    let fileNameToDelete = this.state.fileNameToDelete;

    console.log("fileNameToDelete"+fileNameToDelete)

    if (file.length === 0)
    {
        this.setState({AllInputsRequired:ERROR_ALL_INPUTS_REQUIRED}); 
    }
    
    else
    {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('updateId', userId);
        formData.append('fileNameToDelete',fileNameToDelete)


        try
            {
                const postRequest = await axios.put(APIUpdateResearcherImage, formData,{headers})
                let result = postRequest.data;
                this.setState({EditProfileImageSuccess:result});
                this.setState({ShowEditProfileImageSuccessAlert:true});
            }
            catch (error)
                {this.setState({EditProfileImageError:error.message}); this.setState({ShowEditProfileErrorAlert:true});};
    }
}
logoutUser = () => {
    clearLocalStorageData ();
    this.props.navigate("/");
};
deleteUserAccount =  async () => {
    let deleteId = this.state.userId
    try {
            const response = await axios.delete(APIResearcherAccountDelete+deleteId, {headers});
            this.setState({ShowAlertSuccessPostMsg:true});
            this.setState({ShowAlertErrorPostMsg:false});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({SuccessPostMsg:response.data});
            setTimeout(()=>this.logoutUser(),5000);
        } 
        catch (error) {
            this.setState({ShowAlertErrorPostMsg:true});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({ShowAlertSuccessPostMsg:false});
            this.setState({ErrorPostMsg:ERROR_POST+error.message})
            }
}
        
render() {
    const { 
            userUniversity, userEmail,UniversitiesData,Password,
            userName, userTitle, userContact,PasswordRetype,
            userAbout, userLevel, userNationality,
            userTwitter, userFacebook, userInstagram, userLinkedin,
            PasswordInputsRequired,UserTypedPassword,userLoggedIn,
            PasswordDoNotMatch,SelectedImage,profileImage,
            DBPasswordDoNotMatch,ShowDeleteModel,
        } =this.state;

return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                    <strong className='strong-text white-text' >User Profile </strong><span className='strong-text white-text'>View</span>
                    <div className="custom-alert-btn-actions">
                        {/* <ButtonGroup className="mb-2">
                            <Button variant="primary" onClick={()=>this.handleShow()}>
                                Add New
                            </Button>
                        </ButtonGroup> */}
                        </div>
                </div><br/>
                {userLoggedIn ? (<>
                    <div className="row">
                        <section className="section profile">
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                        <img src={APIShowResearcherImage+profileImage} alt="Profile" className="rounded-circle" />
                                        <h2>{userTitle} {userName}</h2>
                                        <h3>{userLevel}</h3>
                                        <div className="social-links mt-2">
                                            <Link to={userTwitter} className="twitter"><i className="bi bi-twitter"></i></Link>
                                            <Link to={userFacebook} className="facebook"><i className="bi bi-facebook"></i></Link>
                                            <Link to={userInstagram} className="instagram"><i className="bi bi-instagram"></i></Link>
                                            <Link to={userLinkedin} className="linkedin"><i className="bi bi-linkedin"></i></Link>
                                        </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <br></br>

                                    <div className="col-xl-8">
                                    <div className="card">
                                    <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="overview" title="Overview">
                                            <div className="profile-tab-content user-profile-card">
                                                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                                    <h5 className="card-title">About</h5>
                                                    <p className="small fst-italic">{userAbout}</p>

                                                    <h5 className="card-title">Profile Details</h5>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label ">Full Name</div>
                                                        <div className="col-lg-9 col-md-8">{userName}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">University</div>
                                                        <div className="col-lg-9 col-md-8">{getIdCategory (userUniversity,UniversitiesData)}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Level</div>
                                                        <div className="col-lg-9 col-md-8">{userLevel}</div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Country</div>
                                                        <div className="col-lg-9 col-md-8">{userNationality}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Phone</div>
                                                        <div className="col-lg-9 col-md-8">{userContact}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Email</div>
                                                        <div className="col-lg-9 col-md-8">{userEmail}</div>
                                                    </div>
                                                    <br></br>
                                                    <button type="button" style={{width:'100%'}} className="btn btn-danger"
                                                        onClick={()=> this.handleShowDeleteModel()}
                                                    >Delete My Account</button>
                                                    <br></br><br></br>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="uploadPhoto" title="Upload Photo">
                                            <div className="profile-tab-content user-profile-card">
                                                <div className="row mb-3">
                                                    <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                                    <div className="col-md-4 col-lg-6">
                                                    <div>
                                                        {SelectedImage && (
                                                            <div>
                                                            <h3>New Image:</h3>
                                                                <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                            </div>
                                                        )}
                                                        </div>
                                                        <div className="pt-2">
                                                            <input type="file" accept="image/*" onChange={e=>this.handleImageUpload(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" onClick={()=>{this.uploadUserImage()}} className="btn btn-primary">Save Changes</button>
                                                </div><br></br>
                                                <div className="row mb-3">
                                                    <div className="col-md-7 col-lg-9">
                                                        {this.state.ShowUploadProfileImageSuccessAlert ?(<> 
                                                            <Alert variant="primary">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.UploadProfileImageSuccess}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Will Reflect When You Log In Again !!</p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}

                                                        {this.state.ShowUploadProfileImageErrorAlert ?(<> 
                                                            <Alert variant="danger">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.UploadProfileImageError}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Not Recorded You Try Again </p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        

                                        <Tab eventKey="editProfile" title="Edit Profile">
                                            <div className="profile-tab-content user-profile-card">
                                                <div className="row mb-3">
                                                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input type="text" onChange={text=>this.setUserName(text)}  className="form-control form-inputs" id="fullName" value={userName} />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="about" className="col-md-4 col-lg-3 col-form-label">About</label>
                                                    <div className="col-md-7 col-lg-8">
                                                        <textarea onChange={text=>this.setUserAbout(text)} className="form-control form-inputs" value={userAbout} ></textarea>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">University</label>
                                                    <div className="col-md-4 col-lg-6">
                                                    <select className="form-control form-inputs"
                                                            value={userUniversity}
                                                            onChange={text=>this.setUserUniversity(text)}
                                                        >
                                                            <option> Select </option>
                                                            {UniversitiesData && UniversitiesData.map((item, index) => (<option key={index} value={item.id}> {item.name}</option>))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="company" className="col-md-4 col-lg-3 col-form-label">Education Level</label>
                                                    <div className="col-md-4 col-lg-6">
                                                    <select className="form-control form-inputs"
                                                        onChange={text => this.setUserLevel (text)}>
                                                        <option > --Select -- </option>
                                                        <option value="Degree">Degree</option>
                                                        <option value="Masters" >Masters</option>
                                                        <option value="PHD" >PHD</option>
                                                    </select>
                                                    </div>
                                                </div>


                                                <div className="row mb-3">
                                                    <label htmlFor="Country" className="col-md-4 col-lg-3 col-form-label">Nationality</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserNationality(text)} type="text" className="form-control form-inputs" id="Country" value={userNationality} />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserContact(text)} type="text" className="form-control form-inputs" value={userContact}/>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserEmail (text)} type="email" className="form-control form-inputs" id="Email" value={userEmail}/>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Twitter" className="col-md-4 col-lg-3 col-form-label">Twitter Profile</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserTwitter(text)} type="text" className="form-control form-inputs" value={userTwitter} placeholder="https://twiter.com/#"/>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Facebook" className="col-md-4 col-lg-3 col-form-label">Facebook Profile</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserFacebook(text)} type="text" className="form-control form-inputs" value={userFacebook} placeholder="https://facebook.com/#"/>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Instagram" className="col-md-4 col-lg-3 col-form-label">Instagram Profile</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserInstagram (text)}  type="text" className="form-control form-inputs"  value={userInstagram} placeholder="https://instagram.com/#"/>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Linkedin" className="col-md-4 col-lg-3 col-form-label">Linkedin Profile</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserLinkedin (text)} type="text" className="form-control form-inputs" id="Linkedin" value={userLinkedin} placeholder="https://linkedin.com/#"/>
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" onClick={()=>{this.updateUserDetails()}} className="btn btn-primary">Save Changes</button>
                                                </div><br></br>
                                                <div className="row mb-3">
                                                    <div className="col-md-7 col-lg-9">
                                                        {this.state.ShowEditProfileSuccessAlert ?(<> 
                                                            <Alert variant="primary">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditProfileSuccess}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Will Reflect When You Log In Again !!</p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}

                                                        {this.state.ShowEditProfileErrorAlert ?(<> 
                                                            <Alert variant="danger">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditProfileError}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Not Recorded You Try Again </p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="editPhoto" title="Edit Photo">
                                            <div className="profile-tab-content user-profile-card">
                                                <div className="row mb-3">
                                                    <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                                    <div className="col-md-4 col-lg-6">
                                                    <div>
                                                        {SelectedImage && (
                                                            <div>
                                                            <h3>New Image:</h3>
                                                                <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                            </div>
                                                        )}
                                                        </div>
                                                        <div className="pt-2">
                                                            <input type="file" accept="image/*" onChange={e=>this.handleImageUpload(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" onClick={()=>{this.updateUserImage()}} className="btn btn-primary">Save Changes</button>
                                                </div><br></br>
                                                <div className="row mb-3">
                                                    <div className="col-md-7 col-lg-9">
                                                        {this.state.ShowEditProfileImageSuccessAlert ?(<> 
                                                            <Alert variant="primary">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditProfileImageSuccess}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Will Reflect When You Log In Again !!</p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}

                                                        {this.state.ShowEditProfileImageErrorAlert ?(<> 
                                                            <Alert variant="danger">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditProfileImageError}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Not Recorded You Try Again </p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="changePassword" title="Change Password">
                                            <div className='profile-tab-content user-profile-card'>
                                                <div className="row mb-3">
                                                    <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input type="password" className="form-control form-inputs change-password-form-inputs" placeholder="**************"
                                                        onChange={text => this.setTypedUserPassword(text)} value={UserTypedPassword}
                                                        />
                                                        <p className="primary-text red-text" >{DBPasswordDoNotMatch}</p>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input name="newpassword" type="password" className="form-control form-inputs change-password-form-inputs" value={Password}
                                                        placeholder="**************"
                                                        onChange={text => this.setPassword(text)} />
                                                        <p className="primary-text red-text" >{PasswordDoNotMatch}</p>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input name="renewpassword" type="password" className="form-control form-inputs change-password-form-inputs" 
                                                        placeholder="**************"
                                                            value={PasswordRetype}
                                                            onChange={text => this.setPasswordRetype(text)}
                                                        />
                                                        <p className="primary-text red-text" >{PasswordDoNotMatch}</p>
                                                        <p className="primary-text red-text" >{PasswordInputsRequired}</p>

                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Save Now</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <button type="submit" onClick={()=>this.updateUserPassword()} className="btn btn-primary">Change Password</button>
                                                    </div>
                                                </div><br></br>
                                                <div className="row mb-3">
                                                    <div className="col-md-7 col-lg-9">
                                                        {this.state.ShowEditPasswordSuccessAlert ?(<> 
                                                            <Alert variant="primary">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditPasswordSuccess}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Will Reflect When You Log In Again !!</p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}

                                                        {this.state.ShowEditPasswordErrorAlert?(<> 
                                                            <Alert variant="danger">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditPasswordError}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Not Recorded You Try Again </p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </Tab> 
                                    </Tabs>
                                    {/* Delete Model   */}
                                    <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                        <Modal.Header>
                                            <Modal.Title>Deleting User Account </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                <p>Are You Sure You Want to Delete This Account ??</p>
                                                
                                            </div>
                                            <div className='my-grid-container-2-columns' >
                                                <div className='my-grid-item'>
                                                    <div>
                                                        <p className="primary-text" >
                                                            <b>Researcher Name</b><br></br>
                                                            {userName}
                                                        </p>
                                                        <br></br>
                                                        <p className='primary-text text-danger' ><b>Once You Delete, The Following Data Records Will Be Lost Permanently</b></p>
                                                        <p className="primary-text">1. Viewed Research By You</p>
                                                        <p className="primary-text">2. Research Uploaded By You</p>
                                                        <p className="primary-text">3. Research Projects Uploaded By You</p>
                                                        <p className="primary-text">4. Your Profile Account Information </p>
                                                        <br></br>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.ShowAlertSuccessPostMsg ?(<>
                                                <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.SuccessPostMsg}
                                                </div>
                                            </>):(<></>)}
                                            {this.state.ShowAlertErrorPostMsg ?(<>
                                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.ErrorPostMsg}
                                                </div>
                                            </>):(<></>)}
                                            {this.state.ShowAlertAllInputsRequired ?(<>
                                                <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.AllInputsRequiredError}
                                                </div>
                                            </>):(<></>)}
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                            Close Now
                                        </Button>
                                        <Button variant="primary" onClick={()=>this.deleteUserAccount()}>
                                            Yes Delete My Account
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    </div>
                                    </div>
                            </div>
                        </section>
                    </div>
                    </>):(<> <GuestUser/><br/><br/></>)}
                    </div>
            </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Profile);
