

import React,{Component} from 'react';
import axios from 'axios';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';

import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import { APIResearchersList, APIResearchUpdatePdf, APIResearchUpdateText, AUTH_TOKEN, } from '../../Utilities/APIS';
import NavigationMenu from '../Navigation/NavigationMenu';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Edit extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchersData:[],
        AreasData:[],

        // Editing
        fileNameToDelete:'',
        updateId:'',
        researchTopic:'',
        researchType:'',researchStatus:'',
        pdfFile:'',
        researchAbstract:[],
        researchMainObjectives:[],
        researchSpecificObjectives:[],
        researchHypothesis:[],
        researchTheoreticalFrame:[],
        researchLiteratureReview:[],
        researchMethodology:[],
        researchResults:[],
        researchDiscussionOfResults:[],
        researchRecommendations:[],
        researchConclusions:[],

        // posting 
        postSuccessMsg:'',
        showRequiredAttributesAlert:false,
        showPostSuccessAlert:false,
        showPostingAnimation:false,
        showPostErrorAlert:false,
        showSubmitBtn:true,
        postErrorMsg:'',

        // PDF
        pdfFileName:null,
        pdfFileNameLen:'',
        pdfPlainText:'',
        pdfPlainTextError:'',
        pdfPlainTextSuccess:'',
    }    
}

componentDidMount(){
    this.initializeEditData();
    this.loadData();
}

initializeEditData = () =>{


    const { location } = this.props.router;
    const { state } = location;
    const { 
            updateId,researchTopic,researchType,researchStatus,
            pdfFile,
            researchAbstract,
            researchMainObjectives,
            researchSpecificObjectives,
            researchHypothesis,
            researchTheoreticalFrame,
            researchLiteratureReview,
            researchMethodology,
            researchResults,
            researchDiscussionOfResults,
            researchRecommendations,
            researchConclusions,
        } = state || {};

        this.setState({ updateId: updateId})
        this.setState({researchTopic:researchTopic});
        this.setState({researchType:researchType});
        this.setState({researchStatus:researchStatus})
        this.setState({fileNameToDelete:pdfFile})
        this.setState({researchAbstract:researchAbstract})
        this.setState({researchMainObjectives:researchMainObjectives})
        this.setState({researchSpecificObjectives:researchSpecificObjectives})
        this.setState({researchHypothesis:researchHypothesis})
        this.setState({researchTheoreticalFrame:researchTheoreticalFrame})
        this.setState({researchLiteratureReview:researchLiteratureReview})
        this.setState({researchMethodology:researchMethodology})
        this.setState({researchResults:researchResults})
        this.setState({researchDiscussionOfResults:researchDiscussionOfResults})
        this.setState({researchRecommendations:researchRecommendations})
        this.setState({researchConclusions:researchConclusions})

};

loadData = () =>{

    axios.get(APIResearchersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

setResearchTopic = (text) =>{this.setState({researchTopic:text.target.value});}
setResearchStatusTextInput = (text) =>{this.setState({researchStatus:text.target.value});}
setResearchProjectTextInput = (text) =>{this.setState({researchType:text.target.value});}

PdfTextExtractor = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const fileReader = new FileReader();

        // Read the file as a data URL (base64)
        fileReader.onload = async () => {
            try {
            // Extract the base64 string from the data URL
            const base64String = fileReader.result.split(',')[1];

            // Convert base64 to Uint8Array
            const binaryString = window.atob(base64String);
            const len = binaryString.length;
            const uint8Array = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }

            // Load the PDF document
            const pdf = await pdfjsLib.getDocument(uint8Array).promise;
            let extractedText = '';

            // Loop through each page and extract text
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                const page = await pdf.getPage(pageNumber);
                const textContent = await page.getTextContent();
                
                // Aggregate the text items into plain text
                const pageText = textContent.items.map((item) => item.str).join(' ');
                // extractedText += pageText + '\n';
                extractedText += pageText;
            }

            // Set the extracted text to the state
            this.setState({pdfPlainText:extractedText});
            // console.log("PDF plain text Content "+ extractedText)
            this.setState({pdfPlainTextSuccess:"Success In Processing PDF File TEXT"});
            // check for the similarities in research from the server 
            } catch (err) {
            // console.error(err);
            let response = "Error processing PDF file TEXT " + err.message
            this.setState({pdfPlainTextError:response});
            }
        };

        // Read the file as a data URL (base64 string)
        fileReader.readAsDataURL(file);
        } 
        else {console.log('Please upload a valid file.');
    }
};

handleFile(e) { 
    this.PdfTextExtractor(e);
    // Getting the files from the input 
    let file = e.target.files[0]; 
    let fileName = e.target.files[0].name; 

    this.setState({pdfFileName: file});
    this.setState({pdfFileNameLen:fileName });
} 

clearUpdateAlerts = () =>{
    this.setState({showSubmitBtn:true})
    this.setState({showPostErrorAlert:false});
    this.setState({showPostSuccessAlert: false});
}

updateResearchText =  async () => {
    this.setState({showPostingAnimation:true});

    let updateId = this.state.updateId;
    let researchTopic = this.state.researchTopic;
    let researchStatus = String(this.state.researchStatus);
    let researchType = String(this.state.researchType);
    
    let researchAbstract = JSON.stringify(this.state.researchAbstract);
    let researchMainObjectives = JSON.stringify(this.state.researchMainObjectives);
    let researchSpecificObjectives = JSON.stringify(this.state.researchSpecificObjectives);
    let researchHypothesis = JSON.stringify(this.state.researchHypothesis);
    let researchTheoreticalFrame = JSON.stringify(this.state.researchTheoreticalFrame);
    let researchLiteratureReview = JSON.stringify(this.state.researchLiteratureReview);
    let researchMethodology = JSON.stringify(this.state.researchMethodology);
    let researchResults = JSON.stringify(this.state.researchResults);
    let researchDiscussionOfResults = JSON.stringify(this.state.researchDiscussionOfResults);
    let researchRecommendations = JSON.stringify(this.state.researchRecommendations);
    let researchConclusions = JSON.stringify(this.state.researchConclusions);

    const formData = new FormData();
    formData.append('researchStatus',researchStatus) 
    formData.append('researchType',researchType) 
    formData.append('researchTopic',researchTopic) 
    formData.append('researchAbstract',researchAbstract) 
    formData.append('researchMainObjectives',researchMainObjectives) 
    formData.append('researchSpecificObjectives',researchSpecificObjectives) 
    formData.append('researchHypothesis',researchHypothesis) 
    formData.append('researchTheoreticalFrame',researchTheoreticalFrame) 
    formData.append('researchLiteratureReview',researchLiteratureReview) 
    formData.append('researchMethodology',researchMethodology) 
    formData.append('researchResults',researchResults) 
    formData.append('researchDiscussionOfResults',researchDiscussionOfResults) 
    formData.append('researchRecommendations',researchRecommendations) 
    formData.append('researchConclusions',researchConclusions) 
    formData.append('updateId',updateId)

    try {
            const response = await axios.put(APIResearchUpdateText, formData, {headers});
            this.setState({postSuccessMsg:response.data});
            this.setState({showPostSuccessAlert: true});
            this.setState({showPostingAnimation:false});
            this.setState({showPostErrorAlert:false});
            this.setState({showSubmitBtn:false});  
        } 
        catch (error) {
                this.setState({postErrorMsg:error.message});
                this.setState({showPostSuccessAlert: false}); 
                this.setState({showPostErrorAlert:true});
            }
}
updateResearchPdf =  async () => {
    let fileNameToDelete = this.state.fileNameToDelete
    let pdfPlainText = this.state.pdfPlainText;
    let file = this.state.pdfFileName;
    let pdfFileNameLen = this.state.pdfFileNameLen;
    let updateId = this.state.updateId;
    
    

    if (pdfFileNameLen.length === 0)
    {this.setState({showRequiredAttributesAlert:true});}
    else{
        const formData = new FormData();
        formData.append('fileName', file);
        formData.append('fileNameToDelete',fileNameToDelete) 
        formData.append('pdfPlainText',pdfPlainText) 
        formData.append('updateId', updateId);
        try {
            const response = await axios.put(APIResearchUpdatePdf, formData, {headers});
            this.setState({postSuccessMsg:response.data});
            this.setState({showPostSuccessAlert: true});
            this.setState({showPostingAnimation:false});
            this.setState({showPostErrorAlert:false});
            this.setState({showSubmitBtn:false});                  
        } 
        catch (error) {
                this.setState({postErrorMsg:error.message});
                this.setState({showPostSuccessAlert: false}); 
                this.setState({showPostErrorAlert:true});
            }
    }
    
}

render() {
    const {
            researchAbstract,
            researchMainObjectives,
            researchSpecificObjectives,
            researchHypothesis,
            researchTheoreticalFrame,
            researchLiteratureReview,
            researchMethodology,
            researchResults,
            researchDiscussionOfResults,
            researchRecommendations,
            researchConclusions,
    } =this.state;


return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Research Summary </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                <Link to={"/researches"} >
                                    <button type='button' className='btn btn-dark black-bg-color'> Researches</button>
                                </Link>
                            </ButtonGroup>
                            </div>
                    </div>

                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Researcher" >
                                    <Row>
                                        <Col sm={2}>
                                        <Nav variant="pills" className="flex-column w3-pale-blue">
                                            <Nav.Item>
                                            <Nav.Link eventKey="Topic">Topic</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Abstract">Abstract</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="MainObjectives">Main Objectives</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="SpecificObjectives">Specific Objectives</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Hypothesis">Hypothesis</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="TheoreticalFrame">Theoretical Frame Work</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Literature">Literature Review</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Methodology">Methodology</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="ResearchResults">Research Results</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="DiscussionOfResults">Discussion Of Results</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Recommendations">Recommendations</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Conclusions">Conclusions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Attachments">Attachments</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            {/* <div className="w3-pale-blue "></div> */}

                                            <Tab.Pane eventKey="Topic">
                                                <p className='research-preview-title primary-text'>Topic</p>
                                                <input type="text"  onChange={text=>this.setResearchTopic(text)} defaultValue={this.state.researchTopic} className="form-control form-inputs"  autoComplete="off" placeholder="Topic / Title Name"/>

                                                <h5 className="card-title">Research Status</h5>
                                                <select style={{width:"50%"}} className="form-control form-inputs"
                                                    defaultValue={this.state.researchStatus}
                                                    onChange={text => this.setResearchStatusTextInput(text)}
                                                >
                                                    <option value={this.state.researchStatus} > {this.state.researchStatus} </option>
                                                    <option value="Published">Published</option>
                                                    <option value="Completed" >Completed</option>
                                                    <option value="Working Progress" >Working Progress </option>
                                                </select>
                                                <br></br>

                                                <label className="primary-text">Research Project </label>
                                                <select style={{width:"50%"}} className="form-control form-inputs"
                                                    defaultValue={this.state.researchType}
                                                    onChange={text => this.setResearchProjectTextInput(text)}
                                                >
                                                    <option value={this.state.researchType} > {this.state.researchType} </option>
                                                    <option value="Thesis" >Thesis -Phd</option>
                                                    <option value="Journal" >Journal - Others</option>
                                                    <option value="Dissertation">Dissertation - Master</option>
                                                </select>
                                                <br></br>

                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Abstract">
                                                <p className='research-preview-title primary-text'>Abstract - {researchAbstract.length}</p>

                                                {researchAbstract && researchAbstract.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchAbstract]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchAbstract: clonedItems });
                                                            }}
                                                            /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            
                                            <Tab.Pane eventKey="MainObjectives">
                                                <p className='research-preview-title primary-text'>Main Objectives</p>
                                                {researchMainObjectives && researchMainObjectives.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                        defaultValue={item.data}
                                                        onChange={(data) => {
                                                            const clonedItems = [...this.state.researchMainObjectives]; // Shallow copy
                                                            clonedItems[index].data = data.target.value;
                                                            this.setState({researchMainObjectives: clonedItems });
                                                        }}
                                                        /><br></br>
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="SpecificObjectives">
                                                <p className='research-preview-title primary-text'>Specific Objectives</p>
                                                {researchSpecificObjectives && researchSpecificObjectives.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                        defaultValue={item.data}
                                                        onChange={(data) => {
                                                            const clonedItems = [...this.state.researchSpecificObjectives]; // Shallow copy
                                                            clonedItems[index].data = data.target.value;
                                                            this.setState({researchSpecificObjectives: clonedItems });
                                                        }}
                                                        /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="Hypothesis">
                                                <p className='research-preview-title primary-text'>Hypothesis</p>
                                                {researchHypothesis && researchHypothesis.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchHypothesis]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchHypothesis: clonedItems });
                                                            }}
                                                            /><br></br> 
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="TheoreticalFrame">
                                                <p className='research-preview-title primary-text'>Theoretical Frame Work</p>
                                                {researchTheoreticalFrame && researchTheoreticalFrame.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                        defaultValue={item.data}
                                                        onChange={(data) => {
                                                            const clonedItems = [...this.state.researchTheoreticalFrame]; // Shallow copy
                                                            clonedItems[index].data = data.target.value;
                                                            this.setState({researchTheoreticalFrame: clonedItems });
                                                        }}
                                                        /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Literature">
                                                <p className='research-preview-title primary-text'>Literature Review</p>
                                                {researchLiteratureReview && researchLiteratureReview.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchLiteratureReview]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchLiteratureReview: clonedItems });
                                                            }}
                                                            /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Methodology">
                                                <p className='research-preview-title primary-text'>Methodology</p>
                                                {researchMethodology && researchMethodology.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchMethodology]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchMethodology: clonedItems });
                                                            }}
                                                            /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ResearchResults">
                                                <p className='research-preview-title primary-text'>Research Results</p>
                                                {researchResults && researchResults.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchResults]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchResults: clonedItems });
                                                            }}
                                                            /><br></br>
                                                    </div>
                                                ))}
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="DiscussionOfResults">
                                                <p className='research-preview-title primary-text'>Discussion Of Results</p>
                                                {researchDiscussionOfResults && researchDiscussionOfResults.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchDiscussionOfResults]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchDiscussionOfResults: clonedItems });
                                                            }}
                                                            /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Recommendations">
                                                <p className='research-preview-title primary-text'>Recommendations</p>
                                                {researchRecommendations && researchRecommendations.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchRecommendations]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchRecommendations: clonedItems });
                                                            }}
                                                            /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Conclusions">
                                                <p className='research-preview-title primary-text'>Conclusions</p>
                                                {researchConclusions && researchConclusions.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.researchConclusions]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({researchConclusions: clonedItems });
                                                            }}
                                                            /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane> 
                                            <Tab.Pane eventKey="Attachments">
                                                <label className="primary-text">Attach a New PDF file </label>
                                                <input type="file"  accept="application/pdf" style={{width:"50%"}}  className="form-control form-inputs file-uploads-inputs" 
                                                    onChange={e=>this.handleFile(e)}
                                                />
                                                <p className='text-danger'>{this.state.pdfPlainTextError}</p>
                                                <p className='text-primary'>{this.state.pdfPlainTextSuccess}</p>
                                                <br></br>
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateResearchPdf()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showRequiredAttributesAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Required Attributes **</Alert.Heading>
                                                            <p>1. New Research PDF File </p>
                                                        <hr />
                                                        <p className="mb-0">The Mentioned Above Are Required </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Research ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Research !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Research </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane> 

                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Recent Sales --> */}
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Edit);