

import React,{Component} from 'react';
import axios from 'axios';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';

import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import { APIProductUpdate, AUTH_TOKEN, } from '../../Utilities/APIS';
import NavigationMenu from '../Navigation/NavigationMenu';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Edit extends Component {
    
constructor(props){
    
    super(props);
    this.state = {

        // Editing
        updateId:'',
        productTitle:'',
        productDescription:'',
        productDetails:[],

        // posting 
        postSuccessMsg:'',
        showRequiredAttributesAlert:false,
        showPostSuccessAlert:false,
        showPostingAnimation:false,
        showPostErrorAlert:false,
        showSubmitBtn:true,
        postErrorMsg:'',

    }    
}

componentDidMount(){
    this.initializeEditData();
}

initializeEditData = () =>{


    const { location } = this.props.router;
    const { state } = location;
    const { 
            updateId,productTitle,
            productDescription,
            productDetails,
        } = state || {};

        this.setState({ updateId: updateId})
        this.setState({productTitle:productTitle});
        this.setState({productDescription:productDescription})
        this.setState({productDetails:productDetails})
};


setProductTitle = (text) =>{this.setState({productTitle:text.target.value});}
setProductDescriptionTextInput = (text) =>{this.setState({productDescription:text.target.value});}

clearUpdateAlerts = () =>{
    this.setState({showSubmitBtn:true})
    this.setState({showPostErrorAlert:false});
    this.setState({showPostSuccessAlert: false});
}

updateProductText =  async () => {
    this.setState({showPostingAnimation:true});

    let updateId = this.state.updateId;
    let productTitle = this.state.productTitle;
    let productDescription = this.state.productDescription;
    
    let productDetails = JSON.stringify(this.state.productDetails);

    const formData = new FormData();
    formData.append('productDescription',productDescription) 
    formData.append('productTitle',productTitle) 
    formData.append('productDetails',productDetails) 
    formData.append('updateId',updateId)

    try {
            const response = await axios.put(APIProductUpdate, formData, {headers});
            this.setState({postSuccessMsg:response.data});
            this.setState({showPostSuccessAlert: true});
            this.setState({showPostingAnimation:false});
            this.setState({showPostErrorAlert:false});
            this.setState({showSubmitBtn:false});  
        } 
        catch (error) {
                this.setState({postErrorMsg:error.message});
                this.setState({showPostSuccessAlert: false}); 
                this.setState({showPostErrorAlert:true});
            }
}

render() {
    const {
            productDetails,
    } =this.state;


return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Product </strong><span className='strong-text white-text'>Editing</span>
                        <div className="custom-alert-btn-actions">
                            {/* <ButtonGroup className="mb-2">
                                <Link to={"/Productes"} >
                                    <button type='button' className='btn btn-dark black-bg-color'> Productes</button>
                                </Link>
                            </ButtonGroup> */}
                            </div>
                    </div>

                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Producter" >
                                    <Row>
                                        <Col sm={2}>
                                        <Nav variant="pills" className="flex-column w3-pale-blue">
                                            <Nav.Item>
                                            <Nav.Link eventKey="Title">Title</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="productDescription">Description</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="productDetails">Details</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            {/* <div className="w3-pale-blue "></div> */}

                                            <Tab.Pane eventKey="Title">
                                                <p className='research-preview-title primary-text'>Title</p>
                                                <input type="text"  onChange={text=>this.setProductTitle(text)} defaultValue={this.state.productTitle} className="form-control form-inputs"  autoComplete="off" placeholder="Title / Title Name"/>
                                                <br></br>

                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateProductText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Product ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Product !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Product </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="productDescription">
                                            <p className='research-preview-title primary-text'>Product Description</p>
                                                    <textarea className="form-control form-inputs"
                                                        value={this.state.productDescription}
                                                        onChange={text => this.setProductDescriptionTextInput(text)}
                                                    >
                                                    </textarea>
                                                <br></br>
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateProductText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Product ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Product !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Product </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="productDetails">
                                                <p className='research-preview-title primary-text'>Product Details - {productDetails.length}</p>
                                                {productDetails && productDetails.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                            defaultValue={item.data}
                                                            onChange={(data) => {
                                                                const clonedItems = [...this.state.productDetails]; // Shallow copy
                                                                clonedItems[index].data = data.target.value;
                                                                this.setState({productDetails: clonedItems });
                                                            }}
                                                            />
                                                            <br></br>
                                                    </div>
                                                ))}
                                                <br></br>
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateProductText()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Product ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Product !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Product </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>

                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Recent Sales --> */}
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Edit);