
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// redux 
import {Provider} from "react-redux";
import store from './Components/Store/store';

// User
import Profile from './Components/Pages/Navigation/Profile';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword'
import LogIn from "./Components/Auth/LogIn";
import Register from "./Components/Auth/Register";

// Dashboard
import Dashboard from "./Components/Pages/Dashboard/Dashboard";

// Views
import Viewer from './Components/Pages/Views/Viewer';
import ViewsViewMore from './Components/Pages/Views/ViewMore';

// Research
import Researches from "./Components/Pages/Research/Researches";
import ViewMore from './Components/Pages/Research/ViewMore';
import Details from "./Components/Pages/Research/Details";
import Submit from './Components/Pages/Research/Submit';
import Researchers from './Components/Pages/Research/Researchers';
import EditResearch from './Components/Pages/Research/Edit';
import Institutions from './Components/Pages/Research/Institutions';
import Themes from './Components/Pages/Research/Themes';

// Projects 
import Projects from './Components/Pages/Projects/Projects';
import ProjectsProjects from './Components/Pages/Projects/Projects';
import ProjectsSubmit from './Components/Pages/Projects/Submit';
import ProjectDetails from './Components/Pages/Projects/Details';
import ProjectsViewMore from './Components/Pages/Projects/ViewMore';
import ProjectsEdit from './Components/Pages/Projects/Edit';



// products
import ProductsProducts from './Components/Pages/Products/Products';
import ProductsDetails from './Components/Pages/Products/Details';
import ProductsSubmit from './Components/Pages/Products/Submit';
import ProductsViewMore from './Components/Pages/Products/ViewMore';
import ProductsEdit from './Components/Pages/Products/Edit';
// 
function App() {
	return (
		<Provider store={store}>
        {/* <Router basename='user' > */}
        <Router>
			<Routes>
					<Route path="/" element={<LogIn/>} />
					<Route path="/dashboard" element={<Dashboard/>} />
					<Route path="/register" element={<Register />} />
					<Route path="/researches" element={<Researches/>} />
					<Route path="/details" element={<Details/>} />
					<Route path="/submit" element={< Submit />} />
					<Route path="/researchers" element={< Researchers />} />
					<Route path="/projects" element={< Projects />} />
					<Route path="/profile" element={< Profile />} />
					<Route path="/viewer" element={< Viewer />} />
					<Route path="/viewMore" element={< ViewMore />} />
					<Route path="/editResearch" element={< EditResearch />} />
					<Route path="/forget-password" element={< ForgetPassword />} />
					<Route path="/reset-password" element={< ResetPassword />} />
					<Route path="/viewsViewMore" element={< ViewsViewMore />} />

					{/* projects */}
					<Route path="/projectsSubmit" element={< ProjectsSubmit />} />
					<Route path="/projectsProjects" element={< ProjectsProjects />} />
					<Route path="/projectDetails" element={<ProjectDetails />}/>
					<Route path="/projectViewMore" element={<ProjectsViewMore />}/>
					<Route path="/projectEdit" element={<ProjectsEdit />}/>
					<Route path="/institutions" element={<Institutions/>}/>
					<Route path="/themes" element={<Themes/>}/>

					{/* products */}
					<Route path="/productSubmit" element={< ProductsSubmit />} />
					<Route path="/productProduct" element={< ProductsProducts />} />
					<Route path="/productDetails" element={<ProductsDetails />}/>
					<Route path="/productViewMore" element={<ProductsViewMore />}/>
					<Route path="/productEdit" element={<ProductsEdit />}/>


			</Routes>
        </Router>
		</Provider>
		
);}

export default App;
