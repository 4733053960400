

import React,{Component} from 'react';
import axios from 'axios';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import { APIResearchersList, AUTH_TOKEN, } from '../../Utilities/APIS';
import { getIdCategory, getIdFullName } from '../../Utilities/Functions';
import NavigationMenu from '../Navigation/NavigationMenu';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Details extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchersData:[],
        AreasData:[],
    }    
}

componentDidMount(){
    this.loadData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
    }
};

loadData = () =>{

    axios.get(APIResearchersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

render() {
// const {} =this.state;
    const { location } = this.props.router;
    const { state } = location;
    const { 
            researchTopic,researcherName,researchArea,researchStatus,
            pdfFile,
            researchAbstract,
            researchMainObjectives,
            researchSpecificObjectives,
            researchHypothesis,
            researchTheoreticalFrame,
            researchLiteratureReview,
            researchMethodology,
            researchResults,
            researchDiscussionOfResults,
            researchRecommendations,
            researchConclusions,
        } = state || {};
        // console.log("=====>>"+researchAbstract)
return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Research Summary </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                            
                                <Link to={"/researches"} >
                                    <button type='button' className='btn btn-dark black-bg-color'> Researches</button>
                                </Link>
                            </ButtonGroup>
                            </div>
                    </div>

                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Researcher" >
                                    <Row>
                                        <Col sm={2}>
                                        <Nav variant="pills" className="flex-column w3-pale-blue">
                                            <Nav.Item>
                                            <Nav.Link eventKey="Researcher">Author</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Topic">Topic</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Abstract">Abstract</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="MainObjectives">Main Objectives</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="SpecificObjectives">Specific Objectives</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Hypothesis">Hypothesis</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="TheoreticalFrame">Theoretical Frame Work</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Literature">Literature Review</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Methodology">Methodology</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="ResearchResults">Research Results</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="DiscussionOfResults">Discussion Of Results</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Recommendations">Recommendations</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Conclusions">Conclusions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Attachments">Attachments</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            {/* <div className="w3-pale-blue "></div> */}
                                            <Tab.Pane eventKey="Researcher">
                                                <center>
                                                    <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                                        {/* <img src={} alt="Profile" class="rounded-circle"/> */}
                                                    <p className='research-preview-title primary-text'>Author</p>
                                                        <h6>{getIdFullName (researcherName, this.state.ResearchersData)}</h6>
                                                        

                                                        {/* <h3>University of Kisubi</h3>
                                                        <h3>Addtional Details here</h3> */}
                                                    </div>
                                                </center>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="Topic">
                                                <p className='research-preview-title primary-text'>Topic</p>
                                                <p className='research-preview-paragraph primary-text'>{researchTopic}</p>
                                                <h6 className='research-preview-paragraph primary-text'>{getIdCategory(researchArea,this.state.AreasData)}</h6>
                                                <h6 className='research-preview-paragraph primary-text'>{researchStatus}</h6>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Abstract">
                                                <p className='research-preview-title primary-text'>Abstract</p>

                                                {researchAbstract && researchAbstract.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            
                                            <Tab.Pane eventKey="MainObjectives">
                                                <p className='research-preview-title primary-text'>Main Objectives</p>
                                                {researchMainObjectives && researchMainObjectives.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="SpecificObjectives">
                                                <p className='research-preview-title primary-text'>Specific Objectives</p>
                                                {researchSpecificObjectives && researchSpecificObjectives.map((item,index)=>(
                                                <div key={index}>
                                                    <div >
                                                        <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="Hypothesis">
                                                <p className='research-preview-title primary-text'>Hypothesis</p>
                                                {researchHypothesis && researchHypothesis.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="TheoreticalFrame">
                                                <p className='research-preview-title primary-text'>Theoretical Frame Work</p>
                                                {researchTheoreticalFrame && researchTheoreticalFrame.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Literature">
                                                <p className='research-preview-title primary-text'>Literature Review</p>
                                                {researchLiteratureReview && researchLiteratureReview.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Methodology">
                                                <p className='research-preview-title primary-text'>Methodology</p>
                                                {researchMethodology && researchMethodology.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ResearchResults">
                                                <p className='research-preview-title primary-text'>Research Results</p>
                                                {researchResults && researchResults.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="DiscussionOfResults">
                                                <p className='research-preview-title primary-text'>Discussion Of Results</p>
                                                {researchDiscussionOfResults && researchDiscussionOfResults.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Recommendations">
                                                <p className='research-preview-title primary-text'>Recommendations</p>
                                                {researchRecommendations && researchRecommendations.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Conclusions">
                                                <p className='research-preview-title primary-text'>Conclusions</p>
                                                {researchConclusions && researchConclusions.map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane> 
                                            <Tab.Pane eventKey="Attachments">
                                                <iframe
                                                src={pdfFile}
                                                title="My Upload"
                                                width="100%"
                                                height="600px"
                                                style={{ border: '1px solid #ddd', marginTop: '20px' }}
                                                />
                                            </Tab.Pane> 

                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Recent Sales --> */}
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Details);