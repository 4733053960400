

import React,{Component} from 'react';
import axios from 'axios';

import { useLocation, useNavigate, useParams,Link } from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { APIResearcherById, AUTH_TOKEN } from '../../Utilities/APIS';
import { daysPassed, getIdCategory } from '../../Utilities/Functions';
import NavigationMenu from '../Navigation/NavigationMenu';
const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Viewer extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        UniversitiesData:[],
        ViewName:"",
        ViewTitle:"",
        ViewEmail:"",
        ViewContact:"",
        ViewUniversity:"",

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

    }    
}

componentDidMount(){
    this.loadData();
    this.initializeReduxData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});
    }
};

loadData = () =>{

    const { location } = this.props.router;
    const { state } = location;
    const { researchViewer} = state || {};


    axios.get(APIResearcherById+researchViewer,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 

        let name = jsonResults.fullName;
        let email = jsonResults.email;
        let title = jsonResults.title;
        let contact = jsonResults.contact;
        let university = jsonResults.university;

        this.setState({ViewName:name});
        this.setState({ViewEmail:email});
        this.setState({ViewTitle:title});
        this.setState({ViewContact:contact});
        this.setState({ViewUniversity:university});

        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })
}

render() {
    const { location } = this.props.router;
    const { state } = location;
    const { pdfFile,viewDate } = state || {};

return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Research Views </strong><span className='strong-text white-text'>Details</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                <Link to={"/dashboard"} >
                                    <Button className='black-bg-color' >
                                        Dashboard
                                    </Button>
                                </Link>
                            </ButtonGroup> 
                            </div>
                    </div>
                    {this.state.ShowAxiosErrorAlert ?(<>
                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                            <p>{this.state.AllInputsRequiredError}</p>
                            <p>{this.state.AxiosErrorMessage}</p>
                            <p>{this.state.AxiosErrorResponse}</p>
                        </div>
                    </>):(<></>)}

                    <div className="col-12">
                        <div className="card research-display-card">
                            <div className="card-header">
                                <p className="log-green-text">{this.state.ViewName}  </p>
                                <div  className="card-details-div">
                                    <small className="text-muted"><span className='log-green-text'>{daysPassed(viewDate)} days ago</span></small>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* <p className='text-black'>{this.state.ViewContact}</p>
                                <p className='text-black'>{this.state.ViewEmail}</p> */}
                                <p className='text-black'>{getIdCategory(this.state.ViewUniversity,this.state.UniversitiesData)}</p>
                            </div>
                        </div>

                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <iframe
                                src={pdfFile}
                                title="My Upload"
                                width="100%"
                                height="600px"
                                style={{ border: '1px solid #ddd', marginTop: '20px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Viewer);