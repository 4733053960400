

import React,{Component} from 'react';
import axios from 'axios';
import withNavigate from '../Main/withNavigate';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import NextIcon from "../../Utilities/imgs/next.png"
import { APIResearchDelete, APIResearchersList, AUTH_TOKEN } from '../../Utilities/APIS';
import { convertToUpperCase, getIdCategory } from '../../Utilities/Functions';
import NavigationMenu from '../Navigation/NavigationMenu';
import { ERROR_POST } from '../../Utilities/Errors';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class ViewMore extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ViewData:[],
        FilteredData:[],
        UniversitiesData:[],
        ResearchersData:[],
        AreasData:[],
        userName:'',
        researchStatus:'',

        // pagination
        recordsPerPage:10,
        pageNumber:0,

        // model
        ShowDeleteModel:false,
        deleteId:'',
        deleteTitleName:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',
        

    }    
}

componentDidMount() {
    this.loadData();
    this.initializeReduxData();
    // setTimeout(()=>this.loadData(),3000);

    // setTimeout(()=>this.this.loadUser(),3000);
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});

    }
};

loadData = () =>{

    // axios.get(APIResearchesGet,{headers})
    // .then(res => {
    //         let results =JSON.stringify(res.data);
    //         let jsonResults =JSON.parse(results); 
    //         this.setState({FilteredData:jsonResults})
    //         this.setState({ResearchesData:jsonResults})
    //     })
    // .catch(error=>{console.log(error);
    //     this.setState({AxiosErrorMessage:error.message});
    //     this.setState({AxiosErrorResponse:error.request.response});
    //     this.setState({ShowAxiosErrorAlert:true});
    // })

    axios.get(APIResearchersList,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })

    const { location} = this.props;
    const { state } = location;
    const { ViewData ,userName,researchStatus} = state || {};
    this.setState({FilteredData:ViewData})
    this.setState({ViewData:ViewData});
    this.setState({userName:userName});
    this.setState({researchStatus:researchStatus});

}


goToDetailsScreen = (
    Topic,Name,Area,Status,pdfFile,

    researchAbstract,
    researchMainObjectives,
    researchSpecificObjectives,
    researchHypothesis,
    researchTheoreticalFrame,
    researchLiteratureReview,
    researchMethodology,
    researchResults,
    researchDiscussionOfResults,
    researchRecommendations,
    researchConclusions,
) =>{

let detailsProps = {
    researchTopic:Topic,
    researcherName:Name,
    researchArea:Area,
    researchStatus:Status,
    pdfFile:pdfFile,


    researchAbstract:JSON.parse(researchAbstract),
    researchMainObjectives:JSON.parse(researchMainObjectives),
    researchSpecificObjectives:JSON.parse(researchSpecificObjectives),
    researchHypothesis:JSON.parse(researchHypothesis),
    researchTheoreticalFrame:JSON.parse(researchTheoreticalFrame),
    researchLiteratureReview:JSON.parse(researchLiteratureReview),
    researchMethodology:JSON.parse(researchMethodology),
    researchResults:JSON.parse(researchResults),
    researchDiscussionOfResults:JSON.parse(researchDiscussionOfResults),
    researchRecommendations:JSON.parse(researchRecommendations),
    researchConclusions:JSON.parse(researchConclusions),
};
this.props.navigate("/details",{ state: detailsProps });
}

goToEditScreen = (
    updateId,
    Topic,Type,Status,pdfFile,

    researchAbstract,
    researchMainObjectives,
    researchSpecificObjectives,
    researchHypothesis,
    researchTheoreticalFrame,
    researchLiteratureReview,
    researchMethodology,
    researchResults,
    researchDiscussionOfResults,
    researchRecommendations,
    researchConclusions,
) =>{

let detailsProps = {
    updateId: updateId,
    researchTopic:Topic,
    researchType:Type,
    researchStatus:Status,
    pdfFile:pdfFile,


    researchAbstract:JSON.parse(researchAbstract),
    researchMainObjectives:JSON.parse(researchMainObjectives),
    researchSpecificObjectives:JSON.parse(researchSpecificObjectives),
    researchHypothesis:JSON.parse(researchHypothesis),
    researchTheoreticalFrame:JSON.parse(researchTheoreticalFrame),
    researchLiteratureReview:JSON.parse(researchLiteratureReview),
    researchMethodology:JSON.parse(researchMethodology),
    researchResults:JSON.parse(researchResults),
    researchDiscussionOfResults:JSON.parse(researchDiscussionOfResults),
    researchRecommendations:JSON.parse(researchRecommendations),
    researchConclusions:JSON.parse(researchConclusions),
};
this.props.navigate("/editResearch",{ state: detailsProps });
}

setPaginateFilteredData = () =>{
    this.setState({PaginateFilteredData:this.state.ViewData.filter((item, index) => {return (index >= this.state.pageNumber * this.state.recordsPerPage) & (index < (this.state.pageNumber + 1) * this.state.recordsPerPage);})})
}

filterDataByArea = (id) => {
    let code = String(id)
    let filteredArray = this.state.ViewData.filter(item => item.researchArea === code);
    this.setState({FilteredData:filteredArray})
};
resetModelShowAlerts = () =>{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
}
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (deleteId,deleteTileName) => {
    this.setState({deleteId:deleteId}); 
    this.setState({deleteTitleName:deleteTileName}); 
    this.setState({ShowDeleteModel:true});
}
deleteResearchRecord =  async () => {
    let deleteId = this.state.deleteId
    try {
            const response = await axios.delete(APIResearchDelete+deleteId, {headers});
            this.setState({ShowAlertSuccessPostMsg:true});
            this.setState({ShowAlertErrorPostMsg:false});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({SuccessPostMsg:response.data});
        } 
        catch (error) {
            this.setState({ShowAlertErrorPostMsg:true});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({ShowAlertSuccessPostMsg:false});
            this.setState({ErrorPostMsg:ERROR_POST+error.message})
            }
}
displayRecordsData = () =>{

    // const Data = this.state.ViewData;
    return(
            this.state.FilteredData.map((item,index)=>(
                <div key={index}>
                    <div className="card research-display-card">
                        <div className="card-header">
                            <p className="log-green-text">{convertToUpperCase(this.state.userName)} | {getIdCategory(item.universityName,this.state.UniversitiesData)}</p>
                                <div  className="card-details-div">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <p className="log-green-text details-label">Details</p>
                                            </td>
                                            <td>
                                                <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                    onClick={()=>this.goToDetailsScreen(
                                                        item.researchTopic,item.researcherName,item.researchArea,item.researchStatus,
                                                        item.pdfFileName,
                                                        item.researchAbstract,
                                                        item.researchMainObjectives,
                                                        item.researchSpecificObjectives,
                                                        item.researchHypothesis,
                                                        item.researchTheoreticalFrame,
                                                        item.researchLiteratureReview,
                                                        item.researchMethodology,
                                                        item.researchResults,
                                                        item.researchDiscussionOfResults,
                                                        item.researchRecommendations,
                                                        item.researchConclusions,
                                                        item.researchArea,
                                                        item.researchStatus
                                                    )}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    
                                </div>
                        </div>
                        <div className="card-body">
                            <p className='text-black'>{item.researchTopic}</p>
                        </div>
                        <div className="card-footer">
                        <p className='text-black' >Research Status  | <b>{this.state.researchStatus}</b> &nbsp;&nbsp;&nbsp;&nbsp;Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                            
                            <div className="card-details-div" >
                                <button type="button" className="btn btn-success"
                                    onClick={()=>this.goToEditScreen(
                                        item.id,
                                        item.researchTopic,item.researchType,item.researchStatus,
                                        item.pdfFileName,
                                        item.researchAbstract,
                                        item.researchMainObjectives,
                                        item.researchSpecificObjectives,
                                        item.researchHypothesis,
                                        item.researchTheoreticalFrame,
                                        item.researchLiteratureReview,
                                        item.researchMethodology,
                                        item.researchResults,
                                        item.researchDiscussionOfResults,
                                        item.researchRecommendations,
                                        item.researchConclusions,
                                        item.researchArea,
                                        item.researchStatus
                                    )}
                                >Modify</button>
                                &nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-danger"
                                    onClick={()=> this.handleShowDeleteModel(
                                        item.id,
                                        item.researchTopic
                                    )}
                                >Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            ))
    )
}


render() {
    const { ShowDeleteModel } = this.state;
return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >{this.state.researchStatus} Research   </strong><span className='strong-text white-text'>View</span>
                        
                        <div className="custom-alert-btn-actions">
                            <button type="button" class="btn btn-dark mb-2">
                            Total Records <span class="badge bg-white text-dark">{this.state.FilteredData.length}</span>
                            </button>
                        </div>
                    </div>
                    {this.state.ShowAxiosErrorAlert ?(<>
                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                            <p>{this.state.AllInputsRequiredError}</p>
                            <p>{this.state.AxiosErrorMessage}</p>
                            <p>{this.state.AxiosErrorResponse}</p>
                        </div>
                    </>):(<></>)}
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body main-card">
                                    {this.displayRecordsData()}

                                    {/* Delete Model   */}
                                    <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                        <Modal.Header>
                                            <Modal.Title>Deleting A Research Record </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                <p>Are You Sure You Want to Delete This Research Upload ??</p>
                                                <p>Once You Delete, All Data Record Will Be Lost Permanently</p>
                                            </div>
                                            <div className='my-grid-container-2-columns' >
                                                <div className='my-grid-item'>
                                                    <div>
                                                        <p className="primary-text" >
                                                            <b>Topic / Title</b><br></br>
                                                            {this.state.deleteTitleName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.ShowAlertSuccessPostMsg ?(<>
                                                <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.SuccessPostMsg}
                                                </div>
                                            </>):(<></>)}
                                            {this.state.ShowAlertErrorPostMsg ?(<>
                                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.ErrorPostMsg}
                                                </div>
                                            </>):(<></>)}
                                            {this.state.ShowAlertAllInputsRequired ?(<>
                                                <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.AllInputsRequiredError}
                                                </div>
                                            </>):(<></>)}
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                            Close Now
                                        </Button>
                                        <Button variant="primary" onClick={()=>this.deleteResearchRecord()}>
                                            Yes Delete My Research
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* <ReactPaginate 
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={Math.ceil(this.state.ViewData.length / this.state.recordsPerPage)}
                                        onPageChange={(event) => this.setPage(event.selected)}

                                        containerClassName={"paginationButtons"}
                                        previousClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

);
}}

export default withNavigate(ViewMore);