

import React,{Component} from 'react';
import { useLocation, useNavigate, useParams} from 'react-router-dom';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NavigationMenu from '../Navigation/NavigationMenu';
// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Institutions extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        UniversitiesData:[],
        UniversitySearch:false,
        University:'',
        selectedUniversityName:'',

    }    
}

componentDidMount(){
    this.initializeReduxData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});
    }
};

handleUniversitySearchClose = () => {this.setState({UniversitySearch:false});}
handleUniversitySearchShow = () => {this.setState({UniversitySearch:true});}

handleSearchChange = (event) => {
    this.setState({ University: event.target.value });
};

handleSelect = (university) => {
    this.setState({ selectedUniversityName: university.name });
};

render() {

    const {UniversitiesData,UniversitySearch,University,selectedUniversityName} = this.state
    // Filter the universities based on the search query
    const filteredUniversities = this.state.UniversitiesData.length !== 0 && this.state.UniversitiesData.filter((university) =>
        university.name.toLowerCase().includes(University.toLowerCase())
        );
return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Registered Institutions  </strong>
                        <span className='strong-text white-text'>List</span>
                    </div>

                    <div className="col-12">
                        
                        <div className="card">
                            <br></br>
                            <div>
                                <button  className='btn btn-warning' onClick={()=>this.handleUniversitySearchShow()} > Search University / Institution 
                                        &nbsp;&nbsp;&nbsp;
                                <span className="badge bg-success badge-number">{this.state.UniversitiesData.length}</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"><b>A list of all Registered Institutions with UNCHE</b></h5>
                                <ul className="list-group">
                                    
                                    {UniversitiesData.length !==0 &&UniversitiesData.map((item,index)=>(
                                        <div key={index}>
                                            <li  className="list-group-item d-flex justify-content-between align-items-center">
                                                <p>
                                                    <span className="badge bg-dark rounded-pill">{index+1}</span> &nbsp;&nbsp;
                                                    {item.name}
                                                </p>
                                                <span className="badge bg-primary rounded-pill">R</span>
                                            </li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                            <Modal show={UniversitySearch} onHide={()=>this.handleUniversitySearchClose()}>
                                <Modal.Header>
                                <Modal.Title>Search For A University / Institution
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="badge bg-success badge-number">{this.state.UniversitiesData.length}</span>
                                </Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        <div>
                                            <input type="text"  placeholder="Search universities..."
                                            value={University} onChange={this.handleSearchChange} className='form-control form-inputs' />

                                            {/* Dropdown List */}
                                            <ul
                                            style={{
                                                listStyleType: "none",
                                                padding: 0,
                                                margin: 0,
                                                border: "1px solid #ccc",
                                                // maxHeight: "150px",
                                                overflowY: "auto",
                                                width: "100%",
                                                height:"100%",
                                            }}
                                            >
                                            {filteredUniversities.length > 0 ? (
                                                filteredUniversities.map((university,index) => (
                                                <li
                                                    key={university.id}
                                                    onClick={() => this.handleSelect(university)}
                                                    className='university-list-option-label primary-text'>
                                                    <span className="badge bg-success badge-number">{index+1}</span> 
                                                    &nbsp;&nbsp;&nbsp;
                                                    {university.name}
                                                </li>
                                                ))
                                            ) : (
                                                <li className='primary-text'>No results found</li>
                                            )}
                                            </ul>

                                            {/* Selected University */}
                                            <div style={{ marginTop: "20px" }}>
                                                <p className='primary-text'>
                                                    <strong>Selected University:</strong> {selectedUniversityName}
                                                </p>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                <Modal.Footer>
                                    
                                    <Button variant="danger" onClick={()=>this.handleUniversitySearchClose()}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Institutions);