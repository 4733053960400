import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { clearLocalStorageData } from '../../Utilities/Functions';
import { APIShowResearcherImage } from '../../Utilities/APIS';
import { ProductsLinks, ProjectsLinks, ResearchLinks } from '../../Utilities/Data';
function VerticalHeader() {
    // const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userProfileImage, setUserProfileImage] = useState(null);
    



    useEffect(() => {
        const user = localStorage.getItem('UserDetails');
        if (user) {
            const jsonData = JSON.parse(user)
            let userName = jsonData.name;
            let profileImage = jsonData.profileImage;
            setUserName(userName);
            setUserProfileImage(profileImage);

        }
    }, []);

    const logout = () => {
        clearLocalStorageData();
        navigate('/');
    };


    return (
        <>
            <Nav variant="pills" activeKey="1"  className="flex-column vertical-menu-links" >
                <Nav.Item>
                    <Nav.Link eventKey="1"  as={Link} to="/dashboard">
                        Dashboard
                    </Nav.Link>
                </Nav.Item> &nbsp;&nbsp;

                <NavDropdown title="Research" id="nav-dropdown">
                    <NavDropdown.Divider />
                    {ResearchLinks.map((item,index)=>(
                        <div key={index} >
                            <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                                <Link to={item.path} className="nav-links-labels"> {item.name}</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </div>
                    ))}
                </NavDropdown>
                
                <NavDropdown title="Projects" id="nav-dropdown">
                    <NavDropdown.Divider />
                        {ProjectsLinks.map((item,index)=>(
                            <div key={index} >
                                <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                                    <Link to={item.path} className="nav-links-labels" > {item.name}</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                            </div>
                        ))}
                </NavDropdown>
                <NavDropdown title="Products" id="nav-dropdown">
                    <NavDropdown.Divider />
                        {ProductsLinks.map((item,index)=>(
                            <div key={index} >
                                <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                                    <Link to={item.path} className="nav-links-labels" > {item.name}</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                            </div>
                        ))}
                </NavDropdown>
            </Nav>
            <div className='vertical-user-profile-div'>
                <div className='vertical-inner-user-profile-div'>
                    <div className='my-grid-item-user'>
                        <img src={APIShowResearcherImage+userProfileImage} alt="Profile" className=" user-icon" />
                    </div>
                    <div className='my-grid-item-user'>
                    <NavDropdown title={userName} id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.2" as={Link} to="/profile" >Profile</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels' onClick={logout} >Log Out </NavDropdown.Item>
                        <NavDropdown.Divider />
                    </NavDropdown>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerticalHeader;