

import React,{Component} from 'react';
import axios from 'axios';
import withNavigate from '../Main/withNavigate';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import NextIcon from "../../Utilities/imgs/next.png"
import { APIProductDelete, APIResearchersList, AUTH_TOKEN } from '../../Utilities/APIS';
import { daysPassed, getIdCategory } from '../../Utilities/Functions';
import NavigationMenu from '../Navigation/NavigationMenu';
import { ERROR_POST } from '../../Utilities/Errors';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class ViewMore extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ViewData:[],
        FilteredData:[],
        UniversitiesData:[],
        ResearchersData:[],
        AreasData:[],
        userName:'',
        researchStatus:'',

        // pagination
        recordsPerPage:10,
        pageNumber:0,

        // model
        ShowDeleteModel:false,
        deleteId:'',
        deleteTitleName:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',
        

    }    
}

componentDidMount() {
    this.loadData();
    this.initializeReduxData();
    
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});

    }
};

loadData = () =>{
    axios.get(APIResearchersList,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })

    const { location} = this.props;
    const { state } = location;
    const { ViewData ,userName,researchStatus} = state || {};
    this.setState({FilteredData:ViewData})
    this.setState({ViewData:ViewData});
    this.setState({userName:userName});
    this.setState({researchStatus:researchStatus});

}

goToDetailsScreen = (
    researcherName,uploadDate,projectEnd,projectStart,universityName,
    projectTitle,projectFunders,projectDescription,projectMembers
) =>{

let detailsProps = {
    projectTitle:projectTitle,
    researcherName:researcherName,
    uploadDate:uploadDate,
    projectEnd:projectEnd,
    projectStart:projectStart,
    universityName:universityName,
    projectDescription:projectDescription,

    projectFunders:JSON.parse(projectFunders),
    projectMembers:JSON.parse(projectMembers),
    
};
this.props.navigate("/projectDetails",{ state: detailsProps });
}

goToEditScreen = (
    updateId,
    productTitle,
    productDescription,
    productDetails,
) =>{

let detailsProps = {
    updateId: updateId,
    productTitle:productTitle,
    productDescription:productDescription,
    productDetails:JSON.parse(productDetails),
};
this.props.navigate("/productEdit",{ state: detailsProps});
}

setPaginateFilteredData = () =>{
    this.setState({PaginateFilteredData:this.state.ViewData.filter((item, index) => {return (index >= this.state.pageNumber * this.state.recordsPerPage) & (index < (this.state.pageNumber + 1) * this.state.recordsPerPage);})})
}

filterDataByArea = (id) => {
    let code = String(id)
    let filteredArray = this.state.ViewData.filter(item => item.researchArea === code);
    this.setState({FilteredData:filteredArray})
};
resetModelShowAlerts = () =>{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
}
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (deleteId,deleteTileName) => {
    this.setState({deleteId:deleteId}); 
    this.setState({deleteTitleName:deleteTileName}); 
    this.setState({ShowDeleteModel:true});
}
deleteRecord =  async () => {
    let deleteId = this.state.deleteId
    try {
            const response = await axios.delete(APIProductDelete+deleteId, {headers});
            this.setState({ShowAlertSuccessPostMsg:true});
            this.setState({ShowAlertErrorPostMsg:false});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({SuccessPostMsg:response.data});
        } 
        catch (error) {
            this.setState({ShowAlertErrorPostMsg:true});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({ShowAlertSuccessPostMsg:false});
            this.setState({ErrorPostMsg:ERROR_POST+error.message})
            }
}
displayRecordsData = () =>{
    // const Data = this.state.ViewData;
    return(
            this.state.FilteredData.map((item,index)=>(
                <div key={index}>

                    <div className="alert alert-primary alert-dismissible fade show" role="alert">
                        <h4 className="alert-heading">{this.state.userName} |  {getIdCategory (item.universityName,this.state.UniversitiesData)}</h4>
                        <p>{item.productTitle}</p>
                        <p>{item.productDescription}</p>

                        <div  className="card-days-div">
                            <span className='log-green-text'>{daysPassed (item.uploadDate)} days ago</span>
                        </div>

                        <hr/>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">Details</button> */}
                        <div >
                            <button type="button" className="btn btn-success"
                                onClick={()=>this.goToEditScreen(
                                    item.id,
                                    item.productTitle,
                                    item.productDescription,
                                    item.productDetails,
                                )}
                            >Modify Product</button>
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn btn-danger"
                                onClick={()=> this.handleShowDeleteModel(
                                    item.id,
                                    item.productTitle
                                )}
                            >Delete Product</button>
                        </div>

                        <div  className="card-details-div">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <p className="log-green-text details-label">Details</p>
                                    </td>
                                    <td>
                                        <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                            onClick={()=>this.goToDetailsScreen(
                                                item.researcherName,item.uploadDate,
                                                item.universityName,item.productTitle,item.productDescription,
                                                item.productDetails
                                            )}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ))
    )
}


render() {
    const { ShowDeleteModel } = this.state;
return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >{this.state.researchStatus} Projects   </strong><span className='strong-text white-text'>View</span>
                        
                        <div className="custom-alert-btn-actions">
                            <button type="button" className="btn btn-dark mb-2">
                            Total Records <span className="badge bg-white text-dark">{this.state.FilteredData.length}</span>
                            </button>
                        </div>
                    </div>
                    {this.state.ShowAxiosErrorAlert ?(<>
                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                            <p>{this.state.AllInputsRequiredError}</p>
                            <p>{this.state.AxiosErrorMessage}</p>
                            <p>{this.state.AxiosErrorResponse}</p>
                        </div>
                    </>):(<></>)}
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body main-card">
                                    {this.displayRecordsData()}

                                    {/* Delete Model   */}
                                    <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                        <Modal.Header>
                                            <Modal.Title>Deleting A Product Record </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                <p>Are You Sure You Want to Delete This Product Upload ??</p>
                                                <p>Once You Delete, All Data Record Will Be Lost Permanently</p>
                                            </div>
                                            <div className='my-grid-container-2-columns' >
                                                <div className='my-grid-item'>
                                                    <div>
                                                        <p className="primary-text" >
                                                            <b>Topic / Title</b><br></br>
                                                            {this.state.deleteTitleName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.ShowAlertSuccessPostMsg ?(<>
                                                <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.SuccessPostMsg}
                                                </div>
                                            </>):(<></>)}
                                            {this.state.ShowAlertErrorPostMsg ?(<>
                                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.ErrorPostMsg}
                                                </div>
                                            </>):(<></>)}
                                            {this.state.ShowAlertAllInputsRequired ?(<>
                                                <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                                    {this.state.AllInputsRequiredError}
                                                </div>
                                            </>):(<></>)}
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                            Close Now
                                        </Button>
                                        <Button variant="primary" onClick={()=>this.deleteRecord()}>
                                            Yes Delete My Product
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* <ReactPaginate 
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={Math.ceil(this.state.ViewData.length / this.state.recordsPerPage)}
                                        onPageChange={(event) => this.setPage(event.selected)}

                                        containerClassName={"paginationButtons"}
                                        previousClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

);
}}

export default withNavigate(ViewMore);