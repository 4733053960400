

import React,{Component} from 'react';
import axios from 'axios';

import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import Alert from 'react-bootstrap/Alert';

import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import { APIResearchersList,  AUTH_TOKEN, } from '../../Utilities/APIS';
import NavigationMenu from '../Navigation/NavigationMenu';
import { APIProjectsUpdate } from '../../Utilities/APIS';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Edit extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchersData:[],
        AreasData:[],

        // Editing
        updateId:'',
        projectTitle:'',
        projectStart:'',projectEnd:'',
        projectDescription:'',
        projectFunders:[],
        projectMembers:[],

        // posting 
        postSuccessMsg:'',
        showRequiredAttributesAlert:false,
        showPostSuccessAlert:false,
        showPostingAnimation:false,
        showPostErrorAlert:false,
        showSubmitBtn:true,
        postErrorMsg:'',
    }    
}

componentDidMount(){
    this.initializeEditData();
    this.loadData();
}

initializeEditData = () =>{


    const { location } = this.props.router;
    const { state } = location;
    const { 
            updateId,projectTitle,
            projectStart,projectEnd,
            projectDescription,
            projectFunders,
            projectMembers,
        } = state || {};

        this.setState({ updateId: updateId})
        this.setState({projectTitle:projectTitle});
        this.setState({projectStart:projectStart});
        this.setState({projectEnd:projectEnd})
        this.setState({projectDescription:projectDescription})
        this.setState({projectFunders:projectFunders})
        this.setState({projectMembers:projectMembers})
};

loadData = () =>{

    axios.get(APIResearchersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

setProjectTitle = (text) =>{this.setState({projectTitle:text.target.value});}
setProjectStartTextInput = (text) =>{this.setState({projectStart:text.target.value});}
setProjectEndTextInput = (text) =>{this.setState({projectEnd:text.target.value});}
setProjectDescriptionTextInput = (text) =>{this.setState({projectDescription:text.target.value});}


clearUpdateAlerts = () =>{
    this.setState({showSubmitBtn:true})
    this.setState({showPostErrorAlert:false});
    this.setState({showPostSuccessAlert: false});
}

updateTextRecord =  async () => {
    this.setState({showPostingAnimation:true});

    let updateId = this.state.updateId;
    let projectTitle = this.state.projectTitle;
    let projectEnd = this.state.projectEnd;
    let projectStart = this.state.projectStart;
    
    let projectDescription = this.state.projectDescription;
    let projectFunders = JSON.stringify(this.state.projectFunders);
    let projectMembers = JSON.stringify(this.state.projectMembers);

    const formData = new FormData();
    formData.append('projectEnd',projectEnd) 
    formData.append('projectStart',projectStart) 
    formData.append('projectTitle',projectTitle) 
    formData.append('projectDescription',projectDescription) 
    formData.append('projectFunders',projectFunders) 
    formData.append('projectMembers',projectMembers) 
    formData.append('updateId',updateId)

    try {
            const response = await axios.put(APIProjectsUpdate, formData, {headers});
            this.setState({postSuccessMsg:response.data});
            this.setState({showPostSuccessAlert: true});
            this.setState({showPostingAnimation:false});
            this.setState({showPostErrorAlert:false});
            this.setState({showSubmitBtn:false});  
        } 
        catch (error) {
                this.setState({postErrorMsg:error.message});
                this.setState({showPostSuccessAlert: false}); 
                this.setState({showPostErrorAlert:true});
            }
}

render() {
    const {
            projectFunders,
            projectMembers,
    } =this.state;


return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Project  </strong><span className='strong-text white-text'>Editing</span>
                        <div className="custom-alert-btn-actions">
                            {/* <ButtonGroup className="mb-2">
                                <Link to={"/researches"} >
                                    <button type='button' className='btn btn-dark black-bg-color'> Researches</button>
                                </Link>
                            </ButtonGroup> */}
                            </div>
                    </div>

                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Researcher" >
                                    <Row>
                                        <Col sm={2}>
                                        <Nav variant="pills" className="flex-column w3-pale-blue">
                                            <Nav.Item>
                                            <Nav.Link eventKey="Title">Title</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="projectDescription">Description</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Date">Period</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="projectFunders">Investigators</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="projectMembers">Members</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Title">
                                                <p className='research-preview-title primary-text'>Title</p>
                                                <input type="text"  onChange={text=>this.setProjectTitle(text)} defaultValue={this.state.projectTitle} className="form-control form-inputs"  autoComplete="off" placeholder="Title / Title Name"/>
                                                <br></br>
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateTextRecord()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Project ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Projects !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Project </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="projectDescription">
                                                <p className='research-preview-title primary-text'>Projects Description</p>
                                                <textarea  className="form-control form-inputs"
                                                    defaultValue={this.state.projectDescription}
                                                    onChange={text => this.setProjectDescriptionTextInput(text)}
                                                >
                                                </textarea>
                                                <br></br>

                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateTextRecord()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Project ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Projects !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Project </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            
                                            <Tab.Pane eventKey="Date">
                                                <h5 className="card-title">Project Period</h5>
                                                <div className='projects-date-div'>
                                                    <div className='my-grid-item'>
                                                        <label className="primary-text"><b>Start Period</b></label>
                                                        <input type="date"   style={{width:"50%"}}  className="form-control form-inputs" 
                                                            value={this.state.projectStart}
                                                            onChange={text => this.setProjectStartTextInput(text)}
                                                        />
                                                    </div>

                                                    <div className='my-grid-item'>
                                                        <label className="primary-text"><b>End Period</b></label>
                                                        <input type="date"  style={{width:"50%"}}  className="form-control form-inputs file-uploads-inputs" 
                                                            value={this.state.projectEnd}
                                                            onChange={text => this.setProjectEndTextInput (text)}
                                                        />
                                                    </div>
                                                </div>
                                                <br></br>
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateTextRecord()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Project ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Projects !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Project </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="projectFunders">
                                                <p className='research-preview-title primary-text'>Principal Investigator (PIs)</p>
                                                {projectFunders && projectFunders.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                        defaultValue={item.data}
                                                        onChange={(data) => {
                                                            const clonedItems = [...this.state.projectFunders]; // Shallow copy
                                                            clonedItems[index].data = data.target.value;
                                                            this.setState({projectMembers: clonedItems });
                                                        }}
                                                        /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateTextRecord()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Project ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Projects !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Project </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="projectMembers">
                                                <p className='research-preview-title primary-text'>Project Members</p>
                                                {projectMembers && projectMembers.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text"  className="form-control form-inputs" placeholder=".."
                                                        defaultValue={item.data}
                                                        onChange={(data) => {
                                                            const clonedItems = [...this.state.projectMembers]; // Shallow copy
                                                            clonedItems[index].data = data.target.value;
                                                            this.setState({projectMembers: clonedItems });
                                                        }}
                                                        /><br></br>
                                                        
                                                    </div>
                                                ))}
                                                {this.state.showSubmitBtn ? (<>
                                                    <button type="button" className='btn btn-success'  onClick={()=>this.updateTextRecord()}>
                                                        Update
                                                    </button>  &nbsp;&nbsp;
                                                </>):(<></>)}
                                                {this.state.showPostingAnimation ? (<>
                                                    <center>
                                                    <div className="spinner-border text-danger" role="status">
                                                        <span className="visually-hidden posting-spinner" >Posting...</span>
                                                    </div>
                                                    <p className='text-danger'><b>... Updating Your  Project ...</b></p>
                                                </center>
                                                </>):(<></>)}
                                                {this.state.showPostSuccessAlert ?(<> 
                                                    <Alert variant="primary">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postSuccessMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Thank You For Updating Your Projects !!</p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                                {this.state.showPostErrorAlert ?(<> 
                                                    <Alert variant="danger">
                                                        <Alert.Heading>Response</Alert.Heading>
                                                        <p>{this.state.postErrorMsg}</p>
                                                        <hr />
                                                        <p className="mb-0">Try Again Updating Your Project </p>
                                                    </Alert><br></br>
                                                </>) :(<> </>)}
                                            </Tab.Pane>
                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Recent Sales --> */}
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Edit);